
import React from "react";
import { useTranslation } from "react-i18next"
import HeroImg from "../../components/HeroImg/HeroImg";
import MainLayout from "../../layouts/MainLayout";
import "./interesting-facts.scss";

const InterestingFacts = () => {
  const { t } = useTranslation()
  return (
    <MainLayout metaTitle={t("interesting-facts:metaTitle")}>
      <>
        <HeroImg customClass="interesting-facts-hero" subtitle={t("interesting-facts:heroSubtitle")} title={t("interesting-facts:heroTitle")} backgroundImage="bg/interesting-facts-hero.jpg" />

        <div className="interesting-facts">

          <div className="interesting-facts-section-two">

            <div className="interesting-facts-section-two-box">
              <h3>{t("interesting-facts:sectionOneTitle")}</h3>
              <p>{t("interesting-facts:sectionOneText")}</p>
            </div>

            <div className="interesting-facts-section-two-box">
              <h3>{t("interesting-facts:sectionTwoTitle")}</h3>
              <p>{t("interesting-facts:sectionTwoText")}</p>
            </div>

            <div className="interesting-facts-section-two-box">
              <h3>{t("interesting-facts:sectionThreeTitle")}</h3>
              <p>{t("interesting-facts:sectionThreeText")}</p>
            </div>

            <div className="interesting-facts-section-two-box">
              <h3>{t("interesting-facts:sectionFourTitle")}</h3>
              <p>{t("interesting-facts:sectionFourText")}</p>
            </div>

          </div>

          <div className="interesting-facts-bottom-container">
            <HeroImg customClass="interesting-facts-bottom" backgroundImage="bg/interesting-facts-bottom.jpg">
              {<h3 className="interesting-facts-bottom-text">
                {t("interesting-facts:sectionFiveTitle")}
            </h3>
              }
            </HeroImg>
          </div>
        </div>
      </>
    </MainLayout>
  )
}

export default InterestingFacts;